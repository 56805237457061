<template>
  <div class="catesub">
    <van-empty
      v-if="!goods || goods.length < 1"
      description="此类别下没有商品"
    />
    <van-list
      v-model="loading"
      :finished="finished"
      @load="getGoods"
      class="goods-list"
    >
      <GoodsItem
        v-for="item in goods"
        :key="item.iid"
        :item="item"
        page="catesubNot"
      />
      <!--如果把page设置为'catesub'，商品就不是显示"N个人想要"，而是显示添加到购物车的加号-->
    </van-list>

    <div
      ref="anima"
      class="anima"
      :class="{ 'anima-move': move }"
      :style="{ bottom: bottom, right: right }"
    >
      <i class="to-cart" />
    </div>
  </div>
</template>


<script>
import GoodsItem from "@/components/GoodsItem.vue";
export default {
  name: "Catesub",
  components: {
    GoodsItem,
  },
  data() {
    return {
      move: false,
      goods: null,
      page: 0,
      cid: null,
      loading: false,
      finished: false,
      bottom: "0",
      right: "0",
    };
  },
  computed: {
    CartBadge() {
      return this.$store.state.CartBadge;
    },
  },
  created() {
    this.cid = this.$route.params.id;
  },
  methods: {
    goToCart() {
      this.$router.push({ name: "Cart" });
    },
    // putInCart(event, item) {
    //   //console.log(event.view);
    //   //////
    //   this.right = event.view.innerWidth - event.x - 10 + "px";
    //   this.bottom = event.view.innerHeight - event.y - 10 + "px";
    //   //////
    //   this.$ajax
    //     .post(
    //       "/user/cart/add/",
    //       {

    //         iid: item.id,
    //         sku_id: item.skus[0].sku_id,
    //         quantity: 1,
    //       },
    //       "loading"
    //     )
    //     .then((res) => {
    //       this.move = true;
    //       this.right = "26px";
    //       this.bottom = "60px";
    //       setTimeout(() => {
    //         this.move = false;
    //         this.$store.commit("CartBadge", this.CartBadge + 1);
    //       }, 500);
    //     });
    // },
    getGoods() {
      ;
      this.$ajax
        .post("/market/onsale/search/", {

          cid: this.cid,
          page_no: this.page,
          page_size: 10,
        })
        .then((res) => {
          if (this.page === 0) {
            this.goods = res.data;
          } else {
            if (res.data) {
              this.goods = [...this.goods, ...res.data];
            }
          }
          if (res.next_page < 0) {
            this.finished = true;
          } else {
            this.page = res.next_page;
            this.finished = false;
          }
          this.loading = false;
        });
    },
  },
};
</script>